import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  const [hideMenuItems, setHideMenuItems] = useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="relative overflow-hidden">
      <img src="/assets/ee-bg.svg" alt="" className="w-screen absolute top-0" />
      <div className="z-30 relative h-20 flex justify-between items-center px-40 2xl:px-40 xl:px-40 lg:px-40 md:px-40 sm:px-5 xs:px-5">
        <div className="w-screen flex items-center justify-between">
          <Link to="/">
            <img src="/assets/logo.svg" alt="" className="h-10" />
          </Link>
          <img
            src="/assets/hamburger.svg"
            alt="Hamburger"
            className="h-4 2xl:hidden xl:hidden lg:hidden md:hidden sm:flex xs:flex"
            onClick={() => setHideMenuItems(!hideMenuItems)}
          />
        </div>
        <div className="flex justify-center items-center gap-5 text-white 2xl:flex xl:flex lg:flex md:flex sm:hidden xs:hidden">
          <div>
            <Link
              to="https://outlet.eatexpress.co.uk/login"
              className="w-32 flex justify-center items-center py-2 rounded-lg bg-[#8D8D8D]"
            >
              Login
            </Link>
          </div>
          <div>
            <Link
              to="https://outlet.eatexpress.co.uk/signup"
              className="w-32 flex justify-center items-center py-2 rounded-lg bg-[#25AD34]"
            >
              Sign Up
            </Link>
          </div>
        </div>
      </div>
      <div className="z-30 relative flex flex-col justify-start items-center w-[100%] h-[50vh] 2xl:h-[50vh] xl:h-[40vh] lg:h-[40vh] md:h-[40vh] sm:h-[40vh] xs:h-[40vh] py-10 2xl:py-10 xl:py-10 lg:py-10 md:py-10 sm:py-16 xs:py-16">
        <h1 className="text-center font-bold text-4xl 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-4xl sm:text-3xl xs:text-3xl text-[#2C2B2B]">
          Privacy Policy Notice
        </h1>
        <div className="py-5 font-bold 2xl:w-full xl:w-full lg:w-full md:w-full sm:w-[90%] xs:w-[90%]">
          <p className="text-center 2xl:text-center xl:text-center lg:text-center md:text-center sm:text-left xs:text-left">
            Welcome to Eat Express, your trusted platform for seamless Food
            Transportation and Delivery.
          </p>
          <p className="text-center 2xl:text-center xl:text-center lg:text-center md:text-center sm:text-left xs:text-left">
            This Introduction establishes the privacy principles we uphold to
            ensure your experience is secure and reliable.
          </p>
        </div>

        <div
          className={`relative flex items-center gap-3 2xl:hidden xl:hidden lg:hidden md:hidden sm:flex xs:flex z-30`}
        >
          {hideMenuItems ? (
            <div className="">
              <button
                className="fixed left-[5.4vw] top-7 z-50"
                onClick={() => {
                  setHideMenuItems(!hideMenuItems);
                  navigate("/");
                }}
              >
                <img
                  src="/assets/logo-white.svg"
                  alt="Logo"
                  className="flex text-4xl text-white items-center cursor-pointer fixed left-5 top-5 z-50 h-10"
                  onClick={() => setHideMenuItems(!hideMenuItems)}
                />
              </button>
              <button
                className="flex text-4xl text-white items-center cursor-pointer fixed right-5 top-9 z-50"
                onClick={() => setHideMenuItems(!hideMenuItems)}
              >
                <img src="/assets/cross.svg" alt="" />
              </button>
            </div>
          ) : (
            <div
              className={`flex justify-between items-center w-screen bg-[#111] ${
                !isNavbarVisible ? "hidden" : "flex"
              }`}
            >
              <img
                src="/assets/logo.svg"
                alt="Logo"
                className="flex text-4xl text-white items-center cursor-pointer fixed left-5 top-5 z-50 h-10"
                onClick={() => setHideMenuItems(!hideMenuItems)}
              />
              <img
                src="/assets/hamburger.svg"
                alt="Hamburger"
                className="flex text-4xl text-white items-center cursor-pointer fixed right-5 top-9 z-50 h-4"
                onClick={() => setHideMenuItems(!hideMenuItems)}
              />
            </div>
          )}
          <div
            className={`top-0 right-0 w-screen bg-[#111] py-20 px-5 text-center text-[#F4F4F4] fixed z-40 h-[40%] ease-in-out duration-300 overflow-y-scroll ${
              hideMenuItems ? "translate-x-0 " : "translate-x-full"
            }`}
          >
            <div className="pt-2 z-30 relative">
              <Link
                to="https://outlet.eatexpress.co.uk/login"
                className="w-full flex justify-center items-center py-3 my-5 font-semibold rounded-xl text-lg bg-[#8D8D8D] z-20"
              >
                Login
              </Link>
              <Link
                to="https://outlet.eatexpress.co.uk/signup"
                className="w-full flex justify-center items-center py-3 my-5 font-semibold rounded-xl text-lg bg-[#25AD34] z-20"
              >
                Sign Up
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="w-screen flex justify-center text-[#8D8D8D] relative z-10">
        <div className="w-[90%] 2xl:w-[80%] xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[90%] xs:w-[90%] py-10">
          <h1 className="font-bold text-3xl text-black">I. Overview</h1>
          <h4 className="font-bold text-xl text-[#6b6b6b] py-5">A. Scope</h4>
          <p className="font-medium py-1">
            This notice describes how Eat Express Global Limited, and its
            affiliates collect and use data. This notice applies to all Eat
            Express users globally.
          </p>
          <p className="font-medium py-1">
            <span className="font-bold text-[#2C2B2B]">Drivers:</span> Those who
            receive and accept delivery order requests and pick up the order
            from the food outlet and deliver it to the location entered by food
            outlets and receive payments from Eat Express for their service.
          </p>
          <p className="font-medium py-1">
            <span className="font-bold text-[#2C2B2B]">Food Outlets:</span>{" "}
            Those who create delivery order requests by entering customer and
            food item details.
          </p>
          <p className="font-medium py-1">
            Our privacy practices are subject to applicable laws in the places
            in which we operate. This means that we engage in the practices
            described in this notice in a particular country or region only if
            permitted under the laws of those places.
          </p>
          <p className="font-medium py-1">
            This Privacy Notice is a global commitment that encompasses all
            users of Eat Express&apos;s apps, websites, and services. It serves
            as a comprehensive guide for both drivers and food outlets,
            outlining the practices involved in collecting and utilizing data on
            a global scale.
          </p>
          <p className="font-medium py-1">
            For users in the European Economic Area ("EEA"), the United Kingdom
            ("UK") and Switzerland: Due to data protection and other laws in
            these regions, including the European Union's General Data
            Protection Regulation ("GDPR"), Eat Express does not perform certain
            data collection activities or use data in some of the ways described
            in this notice in the EEA, the UK or Switzerland.
          </p>

          <div>
            <h1 className="font-bold text-3xl text-black pt-5">
              II. Data Collections and Uses
            </h1>
            <div>
              <h4 className="font-bold text-xl text-[#717171] pt-5 pb-3">
                A. The Data We Collect
              </h4>
              <p className="font-bold">
                Eat Express diligently collects data from various sources to
                provide a robust and personalized experience for its users. The
                data collected includes:
              </p>
              <p className="py-2 text-[#2C2B2B] font-bold text-lg">
                Data provided by Users:
              </p>
              <ul className="list-disc list-inside pb-3 font-medium py-[2px]">
                <p className="font-bold text-[#2C2B2B] py-2">Drivers:</p>
                <li>Name</li>
                <li>Contact Number</li>
                <li>Email</li>
                <li>Residential Address</li>
                <li>Vehicle Type (Motorcycle or Car)</li>
                <li>Vehicle Registration Number</li>
                <li>Date of Birth</li>
                <li>Insurance Status with Food Delivery Service</li>
                <li>Display Picture</li>
                <li>Live Location of the Driver when Online</li>
              </ul>

              <ul className="list-disc list-inside pb-3 font-medium py-[2px]">
                <p className="font-bold text-[#2C2B2B] py-2">Food Outlets:</p>
                <li>Name of the Food Outlet</li>
                <li>Email ID</li>
                <li>Contact Number</li>
                <li>Display Picture</li>
                <li>Address of the Outlet</li>
                <li>
                  Exact or approximate location of the outlet for estimating
                  delivery cost
                </li>
              </ul>

              <p className="py-2 text-[#2C2B2B] font-bold text-lg">
                Data Created during Use of Our Services:
              </p>
              <p className="font-medium py-1">
                <span className="font-bold text-[#2C2B2B]">
                  Location Data (Driver):{" "}
                </span>
                We collect precise and approximate location data from drivers'
                mobile devices when the Eat Express app is running in the
                foreground (app open and on the screen) or background (app open
                but not on the screen).
              </p>
              <p className="font-medium py-1">
                <span className="font-bold text-[#2C2B2B]">
                  Location Access when App is in Background:{" "}
                </span>
                Eat Express is committed to safeguarding your privacy. When you
                grant us permission for location access while the app is in the
                background, rest assured that this data is exclusively utilized
                for essential purposes outlined in our privacy policy. This
                includes order tracking, optimizing delivery routes, and
                calculating proximity for efficient order notifications. Your
                location information is treated with utmost confidentiality, and
                we adhere to strict protocols. We do not retain this to uphold
                your privacy rights.
              </p>

              <h4 className="font-bold text-xl text-[#717171] pt-5 pb-3">
                B. How We Use Data
              </h4>
              <ul className="list-disc list-inside pb-3 font-medium">
                <p className="font-bold py-1">
                  Eat Express employs data for a myriad of purposes, including:
                </p>
                <li>Customer Support</li>
                <li>Research and Development </li>
                <li>Enabling Communications between Users</li>
                <li>Non-Marketing Communications</li>
                <li>Legal Proceedings and Requirements</li>
                <li>
                  Exact or Approximate Location of the Outlet for Estimating
                  Delivery Cost
                </li>
              </ul>

              <h4 className="font-bold text-xl text-[#717171] pt-5 pb-3">
                C. Cookies and Third-Party Technology
              </h4>
              <p className="font-medium">
                Eat Express employs cookies and third-party technologies to
                enhance user experience and streamline service delivery.
              </p>

              <h4 className="font-bold text-xl text-[#717171] pt-5 pb-3">
                D. Data Sharing and Disclosure
              </h4>
              <p className="font-bold py-1">Eat Express may Share Data:</p>
              <ul className="list-disc list-inside pb-3 font-medium">
                <p className="font-bold text-[#2C2B2B] py-2">
                  With Other Users:
                </p>
                <li>At the User's Request </li>
                <li>With Eat Express Business Partners </li>
                <li>Emergency Services </li>
                <li>Insurance Companies </li>
                <li>Food Outlets or Restaurants </li>
                <li>
                  With Eat Express Service Providers and Business Partners
                </li>
                <li>For Legal Reasons or in the Event of a Dispute</li>
                <li>With Consent</li>
              </ul>

              <h4 className="font-bold text-xl text-[#717171] pt-5 pb-3">
                E. Data Retention and Deletion
              </h4>
              <p className="font-medium py-1">
                Data retention and deletion policies ensure that user
                information is handled responsibly.{" "}
              </p>
              <p className="font-medium py-1">
                The user (Food Outlet and Driver) can request deletion of their
                account by writing mail to{" "}
                <a href="mailto:eatexpressuk@gmail.com">
                  <span className="text-[#25AD34] font-bold">
                    eatexpressuk@gmail.com
                  </span>
                </a>
              </p>
            </div>
          </div>

          <div>
            <h1 className="font-bold text-3xl text-black pt-5 pb-3">
              III. Choice and Transparency
            </h1>
            <p className="font-bold py-1">
              Users have the power to make informed choices through privacy
              settings, including:
            </p>

            <ul className="list-disc list-inside pb-3 font-medium">
              <p className="font-bold text-[#2C2B2B] py-2">Privacy Settings:</p>
              <li>Location Data Collection (Drivers and Order Recipients)</li>
              <li>Share Live Location (Drivers and Order Recipients) </li>
              <li>Notifications: Account and Trip Updates </li>
              <li>Notifications: Discounts and News </li>
              <li>Communications from Restaurants </li>
              <li>Device Permissions </li>
            </ul>

            <ul className="list-disc list-inside pb-3 font-medium">
              <p className="font-bold text-[#2C2B2B] py-2">
                User Data Requests:
              </p>
              <li>Data Access and Portability </li>
              <li>Changing or Updating data </li>
              <li>Deleting Data </li>
              <li>Objections, Restrictions, and Complaints </li>
            </ul>
          </div>

          <div>
            <h1 className="font-bold text-3xl text-black pt-5 pb-3">
              IV. Legal Information
            </h1>
            <h1 className="text-lg font-bold text-[#2C2B2B] py-1 pt-2">
              Please Note:
            </h1>
            <p className="font-medium py-1">
              <span className="font-bold text-[#717171]">Scope:</span> This
              notice extends globally.
            </p>
            <p className="font-medium py-1">
              <span className="font-bold text-[#8D8D8D]">Access:</span> Users
              have the right to access their data.
            </p>
            <p className="font-medium py-1">
              <span className="font-bold text-[#8D8D8D]">Onward Transfer:</span>{" "}
              Data may be shared with specific entities for service provision.
            </p>
            <p className="font-medium py-1">
              <span>Request from Law Enforcement:</span> We may disclose data in
              response to lawful requests.
            </p>

            <h1 className="text-lg font-bold text-[#2C2B2B] py-3">
              Updates to this Privacy Notice
            </h1>
            <p className="font-medium">
              We may occasionally update this notice. If we make significant
              changes, we will notify users in advance of the changes through
              the Eat Express apps or through other means, such as email. We
              encourage users to periodically review this notice for the latest
              information on our privacy practices.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
