import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const HomePage = () => {
  const [hideMenuItems, setHideMenuItems] = useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const topOffset = 2000; // Adjust this value as needed
      const isMobile = window.innerWidth <= 490; // Adjust breakpoint if needed

      if (isMobile) {
        if (window.scrollY > topOffset) {
          setIsNavbarVisible(true);
        } else {
          setIsNavbarVisible(false);
        }
      } else {
        setIsNavbarVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="">
      <img src="/assets/ee-bg5.svg" alt="" className="absolute top-0" />
      <div className="z-30 relative h-20 2xl:w-full xl:w-full lg:w-full md:w-full sm:w-full xs:w-full flex justify-between items-center px-40 2xl:px-40 xl:px-40 lg:px-40 md:px-40 sm:px-5 xs:px-5">
        <div className="w-screen flex items-center justify-between">
          <Link to="/">
            <img src="/assets/logo.svg" alt="" className="h-10" />
          </Link>
          <img
            src="/assets/hamburger.svg"
            alt="Hamburger"
            className="h-4 2xl:hidden xl:hidden lg:hidden md:hidden sm:flex xs:flex"
            onClick={() => setHideMenuItems(!hideMenuItems)}
          />
        </div>
        <div className="flex justify-center items-center gap-5 text-white 2xl:flex xl:flex lg:flex md:flex sm:hidden xs:hidden">
          <div>
            <Link
              to="/privacypolicy"
              className="w-32 flex justify-center items-center py-2 rounded-lg text-black font-semibold hover:underline hover:underline-offset-2"
            >
              Privacy Policy
            </Link>
          </div>
          <div>
            <Link
              to="mailto:eatexpressuk@gmail.com"
              className="w-32 flex justify-center items-center py-2 rounded-lg bg-[#25AD34]"
            >
              Contact Us
            </Link>
          </div>
        </div>

        <div
          className={`relative flex items-center gap-3 2xl:hidden xl:hidden lg:hidden md:hidden sm:flex xs:flex z-30`}
        >
          {hideMenuItems ? (
            <div className="">
              <button
                className="fixed left-[5.4vw] top-7 z-50"
                onClick={() => {
                  setHideMenuItems(!hideMenuItems);
                  navigate("/");
                }}
              >
                <img
                  src="/assets/logo-white.svg"
                  alt="Logo"
                  className="flex text-4xl text-white items-center cursor-pointer fixed left-5 top-5 z-50 h-10"
                  onClick={() => setHideMenuItems(!hideMenuItems)}
                />
              </button>
              <button
                className="flex text-4xl text-white items-center cursor-pointer fixed right-5 top-9 z-50"
                onClick={() => setHideMenuItems(!hideMenuItems)}
              >
                <img src="/assets/cross.svg" alt="" />
              </button>
            </div>
          ) : (
            <div
              className={`flex justify-between items-center w-screen bg-[#111] ${
                !isNavbarVisible ? "hidden" : "flex"
              }`}
            >
              <img
                src="/assets/logo.svg"
                alt="Logo"
                className="flex text-4xl text-white items-center cursor-pointer fixed left-5 top-5 z-50 h-10"
                onClick={() => setHideMenuItems(!hideMenuItems)}
              />
              <img
                src="/assets/hamburger.svg"
                alt="Hamburger"
                className="flex text-4xl text-white items-center cursor-pointer fixed right-5 top-9 z-50 h-4"
                onClick={() => setHideMenuItems(!hideMenuItems)}
              />
            </div>
          )}
          <div
            className={`top-0 right-0 w-screen bg-[#111] py-20 px-5 text-center text-[#F4F4F4] fixed z-40 h-[50%] ease-in-out duration-300 ${
              hideMenuItems ? "translate-x-0 " : "translate-x-full"
            }`}
          >
            <div className="pt-2 z-30 relative">
              <Link
                to="/privacypolicy"
                className="w-full flex justify-center items-center py-3 my-5 font-semibold rounded-xl text-lg bg-[#8D8D8D] z-20"
              >
                Privacy Policy
              </Link>
              <Link
                to="mailto:eatexpressuk@gmail.com"
                className="w-full flex justify-center items-center py-3 my-5 font-semibold rounded-xl text-lg bg-[#25AD34] z-20"
              >
                Contact
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col-reverse xs:flex-col-reverse justify-around 2xl:justify-around xl:justify-around lg:justify-around md:justify-around sm:justify-end xs:justify-end items-center text-center text-[#2C2B2B] relative h-[60vh] 2xl:h-[60vh] xl:h-[60vh] lg:h-[60vh] md:h-[60vh] sm:h-[50vh] xs:h-[60vh]">
        <div className="flex flex-col items-center w-1/2">
          <h1 className="text-[43px] font-bold py-4 2xl:block xl:block lg:block md:block sm:hidden xs:hidden">
            Welcome to Eat Express
          </h1>
          <p className="px-10 2xl:px-10 xl:px-10 lg:px-10 md:px-10 sm:px-5 xs:px-5 text-lg font-bold py-3 w-[70%] 2xl:w-[70%] xl:w-[70%] lg:w-[70%] md:w-[70%] sm:w-screen xs:w-screen">
            Your Ultimate Delivery Solution for Food and Groceries!
          </p>
          <p className="font-semibold w-[60%] 2xl:w-[60%] xl:w-[60%] lg:w-[60%] md:w-[60%] sm:w-screen xs:w-screen 2xl:px-0 xl:px-0 lg:px-0 md:px-0 sm:px-5 xs:px-5">
            Our cutting-edge platform provides seamless delivery services
            directly from your favorite food and grocery outlets.
          </p>
          <button className="shadow-[0_2.8px_2.2px_rgba(0,_0,_0,_0.034),_0_6.7px_5.3px_rgba(0,_0,_0,_0.048),_0_12.5px_10px_rgba(0,_0,_0,_0.06),_0_22.3px_17.9px_rgba(0,_0,_0,_0.072),_0_41.8px_33.4px_rgba(0,_0,_0,_0.086),_0_100px_80px_rgba(0,_0,_0,_0.12)] bg-[#25AD34] px-12 2xl:px-12 xl:px-12 lg:px-12 md:px-12 sm:px-5 xs:px-5 py-2 my-7 rounded-xl text-white font-semibold">
            Download App
          </button>
        </div>
        <div className="w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-screen xs:w-screen flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col xs:flex-col justify-center items-center pl-20 2xl:pl-20 xl:pl-20 lg:pl-20 md:pl-20 sm:pl-0 xs:pl-5 pt-5 2xl:pr-0 xl:pr-0 lg:pr-0 md:pr-0 sm:pr-5 xs:pr-5">
          <h1 className="text-[44px] 2xl:text-[44px] xl:text-[44px] lg:text-[44px] md:text-[44px] sm:text-[36px] xs:text-[36px] font-bold py-4 2xl:py-4 xl:py-4 lg:py-4 md:py-4 sm:py-0 xs:py-0 2xl:hidden xl:hidden lg:hidden md:hidden sm:block xs:block">
            Welcome to Eat Express
          </h1>
          <img src="/assets/laptop.svg" alt="" className="h-[22rem]" />
        </div>
      </div>

      <div className="h-[28vh] 2xl:h-[28vh] xl:h-[28vh] lg:h-[28vh] md:h-[28vh] sm:h-[65vh] xs:h-[65vh] flex flex-col justify-center 2xl:justify-center xl:justify-center lg:justify-center md:justify-center sm:justify-end xs:justify-end items-center py-6 text-[#413F3F]">
        <h1 className="text-[27px] 2xl:text-[27px] xl:text-[27px] lg:text-[27px] md:text-[27px] sm:text-lg xs:text-lg font-bold py-2 text-center 2xl:px-10 xl:px-10 lg:px-10 md:px-10 sm:px-5 xs:px-5">
          Restoring Outlet Control, One Delivery at a Time
        </h1>
        <p className="w-[33%] 2xl:w-[33%] xl:w-[33%] lg:w-[33%] md:w-[33%] sm:w-screen xs:w-screen text-center font-bold 2xl:pb-0 xl:pb-0 lg:pb-0 md:pb-0 sm:pb-5 xs:pb-5 2xl:px-0 xl:px-0 lg:px-0 md:px-0 sm:px-5 xs:px-5">
          With Eat Express, outlets can effortlessly arrange swift and efficient
          deliveries to satisfy their valued customers' cravings.
        </p>
      </div>
    </div>
  );
};

export default HomePage;
